<template>
<v-timeline-item dense right small :color="color" class="operator_item">
	<span slot="opposite">
		<span class="listTime_day">{{ this.theDay }}</span>
		<span class="listTime_time">{{ this.theTime }}</span>
	</span>
  <span class="elevation-0" >
      <span class="pr-3">{{ this.op_obj.op_operator_name }} </span>
      <span v-html="this.op_obj.op_description"></span>
  </span>
</v-timeline-item>
</template>

<script>
import moment from 'moment';

export default {
    props: ['op_obj','color'],
    data() {
        return {
            theDay:'',
            theTime: '',
        }
    },
    created() {
        this.theDay = moment(this.op_obj.created_at).format('MM/DD');
				this.theTime =  moment(this.op_obj.created_at).format('HH:mm');
    },
}
</script>